<template>
  <div>
    <v-card-title>
      <v-btn depressed @click="onReset">
        {{ $t("reset") }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        class="shrink"
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="spend_simulation_simulated_response"
      :search="search"
      fixed-header
      :loading="isLoading"
      :loading-text="$t('loadingInfo')"
      class="elevation-1"
      dense
    >
      <template
        v-for="(h, index) in headers"
        v-slot:[`item.${h.value}`]="{ item }"
      >
        <div v-if="h.value === 'option'" :key="index">
          <v-select
            @change="onItemChange(item)"
            v-model="item.selectOption"
            :items="item.option"
            item-text="text"
            item-value="value"
            dense
          >
          </v-select>
        </div>
        <div
          v-else-if="h.value.includes('budget') || h.value.includes('revenue')"
          :key="index"
          :class="h.value.includes('simulated') ? classObject(item) : null"
        >
          {{ formatCurrency(item[`${h.value}`]) }}
        </div>
        <div
          v-else-if="h.value.includes('impact')"
          :key="index"
          :class="h.value.includes('simulated') ? classObject(item) : null"
        >
          {{ numberWithCommas(item[`${h.value}`]) }}
        </div>
        <div
          v-else-if="h.value.includes('roi')"
          :key="index"
          :class="h.value.includes('simulated') ? classObject(item) : null"
        >
          {{ `${(item[`${h.value}`] * 100).toFixed(0)}%` }}
        </div>
        <div v-else :key="index">
          {{ item[`${h.value}`] }}
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { formatCurrency, numberWithCommas } from "@/utils";

export default {
  name: "SpendSimulationTable",
  data: () => ({
    search: "",
  }),
  methods: {
    classObject(item) {
      if (Number.isFinite(item.selectOption)) {
        return {
          "increase--text": item.selectOption > 1,
          "decrease--text": item.selectOption < 1,
        };
      } else {
        return {};
      }
    },
    onItemChange(item) {
      this.$store.commit("UPDATE_SPEND_SIMULATED_RESPONSE", item);
    },
    onReset() {
      this.$store.commit("RESET_SPEND_SIMULATED_RESPONSE");
    },
    formatCurrency,
    numberWithCommas,
  },
  computed: {
    isLoading() {
      return this.$store.state.loader.loading;
    },
    headers() {
      return [
        { text: this.$t("change"), value: "option", sortable: false },
        { text: this.$t("promo"), value: "tactic" },
        { text: this.$t("currentSpend"), value: "current_budget" },
        { text: this.$t("optimizedSpend"), value: "optimized_budget" },
        { text: this.$t("simulatedSpend"), value: "simulated_budget" },
        { text: this.$t("currentImpact"), value: "current_impact" },
        {
          text: this.$t("optimizedImpact"),
          value: "optimized_impact",
        },
        {
          text: this.$t("simulatedImpact"),
          value: "simulated_impact",
        },
        {
          text: this.$t("currentImpactableRevenue"),
          value: "current_impact_revenue",
        },
        {
          text: this.$t("optimizedImpactableRevenue"),
          value: "optimized_impact_revenue",
        },
        {
          text: this.$t("simulatedRevenue"),
          value: "simulated_revenue",
        },
        { text: this.$t("currentROI"), value: "current_roi" },
        {
          text: this.$t("optimizedROI"),
          value: "optimized_roi",
        },
        {
          text: this.$t("simulatedROI"),
          value: "simulated_roi",
        },
      ];
    },
    spend_simulation_simulated_response() {
      return this.$store.state.results.spend_simulation_simulated_response;
    },
  },
};
</script>

<style scope>
.v-data-table-header th {
  white-space: nowrap;
}
.v-select__selection--comma {
  overflow: visible;
}
</style>
